import React, {useState} from 'react';

import {Pressable, IconView, XStack, Dialog, AppletWidget} from '@medad-sep/core';

import {LayoutSelectorPopup} from './LayoutSelectorPopup';
import {WidgetSelector} from './WidgetSelector';

interface Props {
  sectionType?: 'row' | 'cell';
  widgetsList?: AppletWidget[];
  onEdit?: (widgetId: string) => void;
  onDelete?: () => void;
  onLayoutChange?: (newLayout: number[]) => void;
}

// todo: split into two component that wrap a generic toolbar one for cell and one for row
export const ToolBar = ({sectionType = 'row', widgetsList, onEdit, onDelete, onLayoutChange}: Props) => {
  const [isOpened, setIsOpened] = useState(false);
  const toolbarColor = sectionType === 'row' ? '$green-400' : '$neutral-600';

  const onDialogOpen = (open: boolean) => {
    !open && setIsOpened(false);
  };

  return (
    <XStack
      top={0}
      left={0}
      borderBottomRightRadius={10}
      position="absolute"
      zIndex={1000}
      backgroundColor={toolbarColor}>
      {sectionType === 'row' && (
        <Dialog
          hideFooter
          open={isOpened}
          onChange={onDialogOpen}
          trigger={
            <Pressable onPress={() => setIsOpened(true)} padding="$1.5">
              <IconView color="$background" icon="Columns" />
            </Pressable>
          }
          title="Insert row"
          content={
            <LayoutSelectorPopup
              onLayoutSelected={(newLayout) => {
                onLayoutChange?.(newLayout);
                setIsOpened(false);
              }}
            />
          }
        />
      )}

      {onEdit && sectionType === 'cell' && (
        <WidgetSelector
          triggerIcon={<IconView icon="Edit" color="$background" />}
          onWidgetSelect={onEdit}
          widgetsList={widgetsList}
        />
      )}

      <Pressable padding="$1.5" onPress={onDelete}>
        <IconView color="$background" icon="Trash2" />
      </Pressable>
    </XStack>
  );
};
