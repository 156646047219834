import React, {useState} from 'react';

import {useTranslation} from 'react-i18next';

import {
  Pressable,
  IconView,
  useResponsiveValue,
  View,
  Dialog,
  XStack,
  getLocalizedText,
  shellScopedKey,
} from '@medad-sep/core';

import {LayoutSelectorPopup} from './LayoutSelectorPopup';

interface Props {
  onAddRow: (layout: number[]) => void;
}

export const RowCreator = ({onAddRow}: Props) => {
  const [isOpened, setIsOpened] = useState(false);
  const defaultOpacity = useResponsiveValue({$sm: 1, $md: 0.3});
  const {t, i18n} = useTranslation();

  const handleDialogTrigger = () => {
    setIsOpened(true);
  };

  const onDialogOpen = (open: boolean) => {
    !open && setIsOpened(false);
  };

  return (
    <Dialog
      hideFooter
      sideBarMode={false}
      open={isOpened}
      onChange={onDialogOpen}
      modelHeight="50%"
      contentProps={{$sm: {padding: '$6'}}}
      trigger={
        <Pressable
          onPress={handleDialogTrigger}
          flex={1}
          animation="quick"
          enterStyle={{opacity: 0.2, scale: 0.9}}
          exitStyle={{opacity: 1, scale: 0.95}}
          hoverStyle={{opacity: 1}}
          opacity={defaultOpacity}>
          <XStack marginVertical="$4" flex={1} justifyContent="center" alignItems="center" width="100%">
            <View flex={1} height={2} backgroundColor="$accent"></View>
            <View
              backgroundColor="$accent"
              borderRadius="$rounded-full"
              height={32}
              width={32}
              alignItems="center"
              justifyContent="center">
              <IconView icon="Plus" color="white" size={22} />
            </View>
            <View flex={1} height={2} backgroundColor="$accent"></View>
          </XStack>
        </Pressable>
      }
      title={getLocalizedText({value: shellScopedKey('dashboard.insertRow'), t, i18n})}
      content={
        <LayoutSelectorPopup
          onLayoutSelected={(layoutValue) => {
            onAddRow(layoutValue);
            setIsOpened(false);
          }}
        />
      }
    />
  );
};
