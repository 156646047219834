import React from 'react';

import {AppScreen, NavigationHeader, YStack, shellScopedKey, useAppStructure} from '@medad-sep/core';

import {getEditorPageView} from '../../components/EditorPageView';
import {LauncherPage} from '../../models/LauncherPage';

import {SettingsStackNavigator, SettingsScreen} from './components/SettingsStackNavigator';
import {settingItems} from './config';
import {SettingsItem} from './models';
import {SettingsMain} from './sections/SettingsMain';
import {UserProfileSection} from './sections/SettingsMain/components/UserProfileSection';

const settingPagesList: SettingsItem[] = Array.prototype.concat.apply([], Object.values(settingItems));

const Component = () => {
  const appStructure = useAppStructure();

  return (
    <SettingsStackNavigator>
      <SettingsScreen
        initialParams={{title: shellScopedKey('settings.title')}}
        name="settings-main"
        component={SettingsMain}
        options={{headerShown: false}}
      />

      {settingPagesList.map(
        ({id, title, Page}) =>
          Page && (
            <SettingsScreen
              key={id}
              name={id}
              component={() => {
                return (
                  <AppScreen styles={{gap: 32}}>
                    <YStack backgroundColor="$background">
                      <UserProfileSection />
                      <NavigationHeader title={title} />
                    </YStack>
                    <Page />
                  </AppScreen>
                );
              }}
              initialParams={{title}}
              options={{headerShown: false}}
            />
          ),
      )}

      {appStructure?.auxiliaryPages.map((group) => {
        return group.pages.map((page) => getEditorPageView(page, SettingsScreen));
      })}
    </SettingsStackNavigator>
  );
};

export const Settings: LauncherPage = {
  id: 'settings-main',
  icon: 'Settings',
  title: shellScopedKey('settings.title'),
  Component,
  hideNavigationHeader: true,
};
