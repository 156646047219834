import React from 'react';

import {shellScopedKey, YStack} from '@medad-sep/core';

import {SettingsItem} from '../../models';

import {ProfileSelector} from './components/ProfileSelector';

const Page = () => {
  return (
    <YStack paddingHorizontal="$32" $sm={{paddingHorizontal: '$16'}}>
      <ProfileSelector />
    </YStack>
  );
};

export const UserProfile: SettingsItem = {
  id: 'user-profile',
  title: shellScopedKey('settings.userProfile.title'),
  icon: 'User',
  Page,
  isPrivate: true,
};
