import {useMutation, useQueryClient} from 'react-query';

import {useAPI} from '@medad-sep/core';

// todo: edit api model for dashboard profile

export function useSaveDashboardProfile() {
  const API = useAPI();
  const queryClient = useQueryClient();
  return useMutation(
    (body: any) =>
      API.put('dashboard-manager/profiles/myProfile', {
        json: {
          settings: {services: body},
        },
      }),
    {
      onSuccess: async () => {
        await queryClient.refetchQueries('dashboardManager/profiles/myProfile');
      },
    },
  );
}
