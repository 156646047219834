import React from 'react';

import {AppCalendar, PageContainer, coreScopedKey} from '@medad-sep/core';

import {LauncherPage} from '../../models/LauncherPage';

const Component = () => {
  return (
    <PageContainer scrollable={false}>
      <AppCalendar />
    </PageContainer>
  );
};

export const Calendar: LauncherPage = {
  id: 'calendar',
  icon: 'Calendar',
  title: coreScopedKey('calendar.title'),
  Component,
  hideNavigationHeader: true,
};
