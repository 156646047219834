import React, {useState} from 'react';

import {useNavigation} from '@react-navigation/native';
import {TouchableOpacity} from 'react-native';

import {
  XStack,
  Typography,
  AppletWidget,
  YStack,
  BidirectionalIcon,
  LocalizedText,
  ShellTrans,
  useAppConfig,
  View,
  VirtualViewPortWidthMapper,
} from '@medad-sep/core';

import {ToolBar} from './ToolBar';

interface Props {
  widget: AppletWidget;
  flex?: number;
  widgetContainerWidth: number | string;
  isEditMode: boolean;
  widgetsList: AppletWidget[];
  isDemoMode: boolean;
  onEdit: (widgetId: string) => void;
  onDelete: () => void;
}

export const WidgetRenderer = ({
  widget,
  flex = 1,
  widgetContainerWidth,
  isEditMode,
  isDemoMode,
  widgetsList,
  onEdit,
  onDelete,
}: Props) => {
  const navigation = useNavigation();
  const appConfig = useAppConfig();

  const [currentWidgetWidth, setcurrentWidgetWidth] = useState(0);

  return (
    <YStack
      height="90%"
      flex={flex + 1}
      alignSelf="flex-end"
      key={widget.id}
      width={isDemoMode ? 'unset' : flex + 1 + '%'}
      $sm={{marginTop: '$4', maxHeight: 320, width: widgetContainerWidth}}
      $emd={{marginTop: '$4', height: 320}}
      onLayout={(e) => setcurrentWidgetWidth(e.nativeEvent.layout.width)}>
      <XStack height="10%" justifyContent="space-between" alignItems="center">
        <Typography variant="body2" fontWeight="700" paddingBottom="$1">
          <LocalizedText value={widget.name} />
        </Typography>
        {widget.mainServiceId && (
          <TouchableOpacity onPress={() => navigation.navigate(widget.mainServiceId as never)}>
            <XStack paddingVertical="1" space="$1" alignItems="center">
              <Typography fontWeight="700" color={appConfig.accentColor}>
                <ShellTrans i18nKey="seeMore" />
              </Typography>

              <BidirectionalIcon icon="ArrowRightCircle" size={13} accent />
            </XStack>
          </TouchableOpacity>
        )}
      </XStack>

      <View
        theme="translucent"
        height="90%"
        overflow="scroll"
        borderRadius="$6"
        position="relative"
        backgroundColor="$background"
        borderWidth={isEditMode ? '$1' : 2}
        borderColor={isEditMode ? '$neutral-600' : '$border'}>
        {isEditMode && <ToolBar widgetsList={widgetsList} onEdit={onEdit} onDelete={onDelete} sectionType="cell" />}
        <VirtualViewPortWidthMapper width={currentWidgetWidth}>
          <widget.Component />
        </VirtualViewPortWidthMapper>
      </View>
    </YStack>
  );
};
