import React, {useState} from 'react';

import {useNavigation} from '@react-navigation/native';

import {
  IconView,
  useIsAuthenticated,
  useAuth,
  Avatar,
  calendarService,
  useIsDemoMode,
  Select,
  Text,
  YStack,
  ShellTrans,
  ListItem,
  useAppDirection,
} from '@medad-sep/core';

import {Settings} from '../../../screens/Settings';

export const ProfileOptions = (): JSX.Element => {
  const [isSelectOpen, setIsSelectOpen] = useState(false);

  const {direction} = useAppDirection();
  const isDemoMode = useIsDemoMode();
  const isAuthenticated = useIsAuthenticated();
  const navigation = useNavigation();
  const {logout} = useAuth();

  const onLogout = async () => {
    if (isAuthenticated) {
      logout();
      navigation.navigate('main' as never);
      calendarService.clearEvents();
    }
    setIsSelectOpen(false);
  };

  return (
    <Select open={isSelectOpen} onOpenChange={setIsSelectOpen} trigger={<Avatar size="sm" />} modelHeight="30%">
      <YStack justifyContent="space-between">
        <ListItem
          direction={direction}
          onPress={() => {
            navigation.navigate(Settings.id as never);
            setIsSelectOpen(false);
          }}
          minWidth="$19"
          prefix={<IconView icon="Settings" size={15} />}
          title={
            <Text marginHorizontal="$2" fontWeight="500">
              <ShellTrans i18nKey="settings.title" />
            </Text>
          }
        />
        <ListItem
          direction={direction}
          onPress={!isDemoMode ? onLogout : () => {}}
          prefix={<IconView icon="LogOut" size={15} />}
          minWidth="$19"
          title={
            <Text marginHorizontal="$2" fontWeight="500">
              <ShellTrans i18nKey="settings.logOut" />
            </Text>
          }
        />
      </YStack>
    </Select>
  );
};
