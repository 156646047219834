import React from 'react';

import {Platform} from 'react-native';

import {AppScreen, shellScopedKey} from '@medad-sep/core';

import {Dashboard} from '../../../../../../Dashboard';
import {BottomPlaceholder, TopPlaceholder} from '../../../../../../TemplatePlaceholder';
import {MainSectionPage} from '../../models/MainSectionPage';

const Component = () => {
  return (
    <AppScreen scrollable={Platform.OS === 'web'}>
      <TopPlaceholder />
      <Dashboard />
      <BottomPlaceholder />
    </AppScreen>
  );
};

export const Home: MainSectionPage = {
  id: 'home',
  title: shellScopedKey('launcher.home.title'),
  icon: 'Home',
  Component,
  options: {
    headerShown: false,
  },
};
