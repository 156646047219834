import React from 'react';

import {Pressable, View, XStack} from '@medad-sep/core';

import {generateInsertRowsLayouts} from './utils/generateInsertRowsLayouts';

interface Props {
  onLayoutSelected: (value: number[]) => void;
}

export const LayoutSelectorPopup = ({onLayoutSelected}: Props) => {
  const rowLayouts = generateInsertRowsLayouts();

  return (
    <XStack flex={1} height="100%" alignItems="center" justifyContent="center" gap="$4" flexWrap="wrap" width="100%">
      {rowLayouts.map((value, index) => {
        return (
          <Pressable
            onPress={() => onLayoutSelected(value)}
            flexDirection="row"
            styles={{gap: 4}}
            maxWidth="45%"
            minWidth="45%"
            $sm={{minWidth: '100%', maxWidth: '100%'}}
            flex={1}
            key={index}
            height="$2">
            {value.map((item, index) => (
              <View height="100%" borderRadius="$rounded" backgroundColor="$accent" key={index} flex={item + 1} />
            ))}
          </Pressable>
        );
      })}
    </XStack>
  );
};
