export const defaultColumns = 4;

export function generateInsertRowsLayouts(columns = defaultColumns) {
  const baseRows = Array.from({length: columns}, (_, i) => Array.from({length: i + 1}, () => 0));
  const additionalRows = [
    [0, 1, 0],
    [0, 1],
    [1, 0],
    [1, 0, 0],
    [0, 0, 1],
  ];

  return baseRows.concat(additionalRows.filter((arr) => arr.length < columns + 1));
}
