import React from 'react';

import {XStack, Typography, Button, ShellTrans, IconView, useIsAuthenticated} from '@medad-sep/core';

interface Props {
  isLoading?: boolean;
  isSaving?: boolean;
  onSave: () => void;
  onEditModeActivated: () => void;
  isEditMode: boolean;
}

export const DashboardHeader = ({isLoading, isSaving, onSave, isEditMode, onEditModeActivated}: Props) => {
  const isAuthenticated = useIsAuthenticated();
  return (
    <XStack marginVertical="$4" flex={1} justifyContent="space-between" alignItems="center" $sm={{marginTop: 0}}>
      <Typography variant="h4" fontWeight="700">
        <ShellTrans i18nKey="launcher.dashboard" />
      </Typography>

      {!isLoading && isAuthenticated && (
        <Button
          prefix={<IconView icon={isEditMode ? 'Save' : 'Settings'} />}
          loading={isSaving}
          onPress={isEditMode ? onSave : onEditModeActivated}>
          <ShellTrans i18nKey={`dashboard.${isEditMode ? 'save' : 'personalize'}`} />
        </Button>
      )}
    </XStack>
  );
};
