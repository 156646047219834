import React from 'react';

import {AppletWidget, IconView, YStack} from '@medad-sep/core';

import {ToolBar} from './ToolBar';
import {WidgetSelector} from './WidgetSelector';

interface Props {
  flex?: number;
  widgetContainerWidth: number | string;
  widgetsList: AppletWidget[];
  isEditMode: boolean;
  onEdit: (widgetId: string) => void;
  onDelete: () => void;
}

// todo: make it use the same container as widget to fix height issues and repeated props
export const CellPlaceholder = ({flex = 1, widgetContainerWidth, isEditMode, widgetsList, onDelete, onEdit}: Props) => {
  return (
    <YStack
      alignItems="center"
      justifyContent="center"
      height="81%"
      flex={flex + 1}
      borderWidth={0.5}
      borderColor="$neutral-400"
      alignSelf="flex-end"
      position="relative"
      borderRadius="$2"
      overflow="hidden"
      minWidth={widgetContainerWidth}
      $sm={{marginTop: '$5', maxHeight: 320}}
      $emd={{marginTop: '$4', height: 320}}>
      {isEditMode && <ToolBar onDelete={onDelete} sectionType="cell" />}

      <WidgetSelector
        triggerIcon={<IconView icon="Plus" color="$background" size={22} />}
        onWidgetSelect={onEdit}
        widgetsList={widgetsList}
      />
    </YStack>
  );
};
