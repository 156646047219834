import React from 'react';

import {PageContainer, YStack, ScrollView} from '@medad-sep/core';

import {Dashboard} from '../../../Dashboard';
import {BottomPlaceholder, TopPlaceholder} from '../../../TemplatePlaceholder';

export const Main = () => {
  return (
    <PageContainer>
      <ScrollView>
        <YStack flexGrow={1}>
          <TopPlaceholder />
          <Dashboard />
          <BottomPlaceholder />
        </YStack>
      </ScrollView>
    </PageContainer>
  );
};
