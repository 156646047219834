import React from 'react';

import {shellScopedKey, AppCalendar} from '@medad-sep/core';

import {MainSectionPage} from '../models/MainSectionPage';

const Component = () => {
  return <AppCalendar />;
};

export const Calendar: MainSectionPage = {
  id: 'calendar',
  title: shellScopedKey('launcher.calendar.title'),
  icon: 'Calendar',
  Component,
  options: {
    headerShown: false,
  },
};
