import React from 'react';

import {useNavigation} from '@react-navigation/native';

import {
  IconView,
  LocalizedText,
  LocalizedTextType,
  IconType,
  Typography,
  Pressable,
  XStack,
  Tooltip,
} from '@medad-sep/core';

interface Props {
  pageData: {id: string; title?: LocalizedTextType; icon: IconType};
  isExpanded: boolean;
  isActive?: boolean;
}

export const MenuItem = ({pageData, isExpanded, isActive}: Props) => {
  const navigation = useNavigation();
  return (
    <XStack
      width="100%"
      padding="$12"
      gap="$12"
      backgroundColor={isActive ? '$lightWhite' : ''}
      borderRadius={isExpanded ? '$8' : '$0'}>
      <Pressable flex={1} onPress={() => navigation.navigate(pageData.id as never)}>
        <XStack justifyContent={isExpanded ? 'flex-start' : 'center'} flex={1}>
          <Tooltip
            hide={isExpanded}
            allowFlip
            placement="left-start"
            content={
              <Typography>
                <LocalizedText value={pageData.title} />
              </Typography>
            }>
            <XStack alignItems="center" width="$3" justifyContent="center">
              <IconView key="icon" icon={pageData.icon} size={24} color="white" />
            </XStack>
          </Tooltip>
          {isExpanded && (
            <Typography key="text" color="white" fontWeight={isActive ? '700' : '500'} marginTop={2}>
              <LocalizedText value={pageData.title} />
            </Typography>
          )}
        </XStack>
      </Pressable>
    </XStack>
  );
};
