import React from 'react';

import {PagePreview, useAppStructure, View} from '@medad-sep/core';

export const TopPlaceholder = () => {
  const appStructure = useAppStructure();

  if (!appStructure?.launcher?.pageTop?.design?.length) return null;

  return (
    <View height="220">
      <PagePreview key={appStructure.launcher.pageTop.id} page={appStructure.launcher.pageTop} />
    </View>
  );
};

export const BottomPlaceholder = () => {
  const appStructure = useAppStructure();

  if (!appStructure?.launcher?.pageBottom?.design?.length) return null;

  return (
    <View height="220">
      <PagePreview key={appStructure.launcher.pageBottom.id} page={appStructure.launcher.pageBottom} />
    </View>
  );
};
