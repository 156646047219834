import React from 'react';

import {createBottomTabNavigator} from '@react-navigation/bottom-tabs';
import {Trans} from 'react-i18next';
import {useTheme} from 'tamagui';

import {IconView, useAppConfig, Typography} from '@medad-sep/core';

import {AllServices} from './screens/AllServices';
import {Calendar} from './screens/Calendar';
import {Home} from './screens/Home';

const MainTabNavigator = createBottomTabNavigator();

const tabs = [Home, AllServices, Calendar];

export const Main = () => {
  const appConfig = useAppConfig();
  const {background} = useTheme();

  const activeColor = appConfig.accentColor as any;

  const getTabBarIcon = (focused: boolean, routeName: string) => {
    const icon = tabs.find((p) => p.id === routeName)?.icon;
    return <IconView icon={icon} color={focused ? activeColor : '$neutral-600'} size={22} />;
  };

  const getTabBarLabel = (focused: boolean, routeName: string) => {
    return (
      <Typography marginBottom={2} variant="caption" color={focused ? activeColor : '$textColor'}>
        <Trans i18nKey={(tabs.find((p) => p.id === routeName)?.title as string) || ''} />
      </Typography>
    );
  };

  return (
    <MainTabNavigator.Navigator
      screenOptions={({route}) => ({
        tabBarStyle: {
          height: 64,
          backgroundColor: background.val,
          borderTopWidth: 0,
          shadowColor: 'transparent',
          paddingTop: 8,
        },
        tabBarActiveTintColor: activeColor,
        tabBarIcon: ({focused}) => getTabBarIcon(focused, route.name),
        tabBarLabel: ({focused}) => getTabBarLabel(focused, route.name),
        tabBarLabelPosition: 'below-icon',
      })}>
      {tabs.map(({id, Component, options, title}) => (
        <MainTabNavigator.Screen
          initialParams={{title: title, isTabScreen: true}}
          key={id}
          name={id}
          options={options}
          component={Component}
        />
      ))}
    </MainTabNavigator.Navigator>
  );
};
