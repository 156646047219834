import React from 'react';

import {Platform} from 'react-native';

import {Avatar, Image, Typography, XStack, YStack, useCurrentUser} from '@medad-sep/core';

export const UserProfileSection = () => {
  const currentUser = useCurrentUser();
  const isMobile = Platform.OS !== 'web';

  const imageHeight = isMobile ? 100 : 150;
  return (
    <YStack width="100%" height={imageHeight} marginBottom={isMobile ? '$9' : '$80'} alignItems="center">
      <Image source={require('../../../../../assets/settingsBG.jpg')} width="100%" height={imageHeight} />

      <XStack
        width="100%"
        position="relative"
        paddingHorizontal="$32"
        bottom={isMobile ? '15%' : '50%'}
        zIndex={2}
        $xs={{height: isMobile ? '$11' : '$12', paddingHorizontal: '$16'}}>
        <Avatar size={isMobile ? '3xl' : '4xl'} />

        <YStack justifyContent="flex-end" marginHorizontal="$16">
          <Typography alignSelf="flex-end" fontWeight="700" fontSize="$20" $xs={{fontSize: '$16'}}>
            {currentUser && currentUser.name}
          </Typography>
          <Typography height={isMobile ? '$3.5' : '$3'} color="$borderHover" fontSize="$16" $xs={{fontSize: '$14'}}>
            {currentUser && currentUser.username}
          </Typography>
        </YStack>
      </XStack>
    </YStack>
  );
};
