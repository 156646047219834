import React, {useState, useMemo} from 'react';

import {Platform} from 'react-native';

import {Pressable, XStack, Typography, IconView, YStack, ListItem, LocalizedText, AppletWidget} from '@medad-sep/core';
interface Props {
  onCreateWidget: (widgetId: string) => void;
  widgetsList: AppletWidget[] | [];
}

export const WidgetsTab = ({widgetsList = [], onCreateWidget}: Props) => {
  const availableAppletIds = useMemo(
    () =>
      widgetsList.reduce((acc, widget) => {
        if (!acc.includes(widget.appletId)) {
          acc.push(widget.appletId);
        }
        return acc;
      }, [] as string[]),
    [widgetsList],
  );
  const [selectedAppletId, setSelectedAppletId] = useState(availableAppletIds[0]);

  return (
    <XStack padding="$20" gap="$20" minHeight="100%" width="100%" $sm={{flexDirection: 'column'}}>
      <YStack gap="$6" $sm={{flexDirection: 'row', overflow: 'scroll'}}>
        {availableAppletIds.map((appletId) => {
          // todo: revise
          const widgetSource = appletId.replace('_', ' ');

          return (
            <ListItem
              key={appletId}
              onPress={() => setSelectedAppletId(appletId)}
              backgroundColor={selectedAppletId === appletId ? '$accentLight' : '$background'}
              title={widgetSource}
              cursor="pointer"
              borderRadius="$4"
              hoverStyle={{borderRadius: '$4', backgroundColor: '$accentLight'}}
              $sm={{width: '45%', alignItems: 'center'}}
            />
          );
        })}
      </YStack>
      <XStack gap="$20" flexWrap="wrap" padding="$20" flex={1} $sm={{padding: 0}}>
        {widgetsList
          .filter(({appletId}) => appletId === selectedAppletId)
          .map(({id, name, Icon}) => {
            return (
              <Pressable
                onPress={() => onCreateWidget(id)}
                key={id}
                flex={1}
                backgroundColor="$neutral-200"
                paddingHorizontal="$12"
                borderRadius="$6"
                alignItems="center"
                justifyContent="center"
                width="25%"
                paddingVertical="$8"
                $sm={{minWidth: '80%', paddingVertical: '$20'}}
                styles={{height: 70, flex: Platform.OS === 'web' ? 1 : 0}}>
                <YStack alignItems="center" justifyContent="center" gap="$6">
                  <IconView icon={Icon} size={20} fill />
                  <Typography fontWeight="700">
                    <LocalizedText value={name} />
                  </Typography>
                </YStack>
              </Pressable>
            );
          })}
      </XStack>
    </XStack>
  );
};
