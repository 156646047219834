import {useMemo} from 'react';

import {AppletWidget, useAppletsList, useIsAuthenticated, useIsDemoMode} from '@medad-sep/core';

export function useUserAvailableWidgets(): {widgetById: Map<string, AppletWidget>; widgetsList: AppletWidget[]} {
  const applets = useAppletsList();
  const isAuthenticated = useIsAuthenticated();
  const isDemoMode = useIsDemoMode();

  return useMemo(() => {
    const widgetsList: AppletWidget[] = [];
    const widgetById = new Map<string, AppletWidget>();

    applets.forEach(({widgets, canView, _context}) => {
      widgets.forEach((widget) => {
        if (isDemoMode || widget.public || (isAuthenticated && canView(widget, _context as any))) {
          widgetById.set(widget.id, widget);
          widgetsList.push(widget);
        }
      });
    });

    return {widgetById, widgetsList};
  }, [applets, isAuthenticated]);
}
