import {useQuery} from 'react-query';
import {v4 as uuid} from 'uuid';

import {useAPI, useIsAuthenticated} from '@medad-sep/core';

import {DashboardContent} from '../model';

// todo: edit api model for dashboard profile
// todo: edit admin settings for dashboard profile

export function useMyDashboardProfile() {
  const API = useAPI();
  const isAuthenticated = useIsAuthenticated();

  return useQuery(['dashboardManager/profiles/myProfile', isAuthenticated], () =>
    API.get('dashboard-manager/profiles/myProfile')
      .json<{settings: {services: DashboardContent}}>()
      .then((content) => {
        // check for old data model
        if (typeof content.settings.services?.[0] === 'string') {
          const widgetNames = content.settings.services as unknown as string[];

          const mappedProfile: DashboardContent = [
            {
              id: uuid(),
              cells: widgetNames.map((widgetId) => ({widgetId, id: uuid()})),
            },
          ];

          return mappedProfile;
        } else {
          return content.settings.services;
        }
      }),
  );
}
