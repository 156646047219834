import React from 'react';

import {View, XStack, AppletWidget, useIsDemoMode} from '@medad-sep/core';

import {CellPlaceholder} from '../components/CellPlaceholder';
import {RowCreator} from '../components/RowCreator';
import {ToolBar} from '../components/ToolBar';
import {WidgetRenderer} from '../components/WidgetRenderer';
import {defaultCellHeight} from '../config';
import {DashboardRow} from '../model';

type Props = {
  row: DashboardRow;
  deleteRow: (rowIndex: number) => void;
  changeRowLayout: (rowIndex: number, newLayout: number[]) => void;
  editCell: (rowIndex: number, cellIndex: number, widgetId?: string) => void;
  deleteCell: (rowIndex: number, columnIndex: number) => void;
  insertRow: (layoutTemplate: number[], insertIndex: number) => void;
  isEditMode: boolean;
  rowIndex: number;
  widgetById: Map<string, AppletWidget>;
  widgetContainerWidth: any;
  widgetsList: AppletWidget[];
};

export const Row = ({
  row,
  isEditMode,
  rowIndex,
  widgetById,
  widgetContainerWidth,
  widgetsList,
  deleteRow,
  changeRowLayout,
  editCell,
  deleteCell,
  insertRow,
}: Props) => {
  const isDemoMode = useIsDemoMode();

  if (row.cells.length === 0) return null;

  return (
    <View key={row.id}>
      <XStack
        position="relative"
        borderRadius="$true"
        paddingHorizontal={isEditMode ? '$3' : 0}
        paddingBottom={isEditMode ? '$3' : 0}
        height={defaultCellHeight}
        gap="$6"
        borderColor={isEditMode ? '$green-400' : '$transparent'}
        borderWidth={isEditMode ? '$1' : '$0.25'}
        $sm={{
          flexDirection: 'column',
          height: isEditMode
            ? row.cells.length * defaultCellHeight + 60
            : row.cells.filter((cell) => cell.widgetId).length * defaultCellHeight,
        }}
        $md={{flexWrap: 'wrap', height: 'auto'}}
        flexWrap={isDemoMode ? 'wrap' : 'unset'}>
        {isEditMode && (
          <ToolBar
            onDelete={() => deleteRow(rowIndex)}
            onLayoutChange={(layout) => changeRowLayout(rowIndex, layout)}
          />
        )}

        {row.cells.map((cell, cellIndex) => {
          const widget = cell.widgetId && widgetById.get(cell.widgetId);

          if (widget) {
            return (
              <WidgetRenderer
                key={cell.id}
                widget={widget}
                flex={cell.flex}
                widgetContainerWidth={widgetContainerWidth}
                isEditMode={isEditMode}
                widgetsList={widgetsList}
                onEdit={(widgetId) => editCell(rowIndex, cellIndex, widgetId)}
                onDelete={() => deleteCell(rowIndex, cellIndex)}
                isDemoMode={isDemoMode}
              />
            );
          }

          return isEditMode ? (
            <CellPlaceholder
              key={cell.id}
              flex={cell.flex}
              widgetContainerWidth={widgetContainerWidth}
              isEditMode={isEditMode}
              widgetsList={widgetsList}
              onEdit={(widgetId) => editCell(rowIndex, cellIndex, widgetId)}
              onDelete={() => deleteCell(rowIndex, cellIndex)}
            />
          ) : null;
        })}
      </XStack>

      {isEditMode && <RowCreator onAddRow={(layout) => insertRow(layout, rowIndex + 1)} />}
    </View>
  );
};
