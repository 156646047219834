import React from 'react';

import {View, YStack, safeAreaStyles, SectionLoader} from '@medad-sep/core';

import {DashboardHeader} from './components/DashboardHeader';
import {Row} from './components/Row';
import {RowCreator} from './components/RowCreator';
import {useDashboardState} from './hooks/useDashboardState';

export const Dashboard = () => {
  const {
    dashboardContent,
    isEditMode,
    widgetById,
    widgetsList,
    isSavingDashboardProfile,
    widgetContainerWidth,
    isLoading,
    saveDashboardConfig,
    insertRow,
    deleteRow,
    changeRowLayout,
    editCell,
    deleteCell,
    setIsEditMode,
  } = useDashboardState();

  return (
    <View
      style={safeAreaStyles.shared}
      marginHorizontal="$6"
      flexDirection="column"
      flexWrap="nowrap"
      justifyContent="center"
      $sm={{marginHorizontal: '$3.5'}}>
      {isLoading && <SectionLoader />}

      <YStack>
        <DashboardHeader
          isSaving={isSavingDashboardProfile}
          onSave={saveDashboardConfig}
          isEditMode={isEditMode}
          isLoading={isLoading}
          onEditModeActivated={() => setIsEditMode(true)}
        />
        {isEditMode && <RowCreator onAddRow={(layout) => insertRow(layout, 0)} />}
        {dashboardContent.map((row, rowIndex) => {
          return (
            <Row
              key={row.id}
              row={row}
              isEditMode={isEditMode}
              rowIndex={rowIndex}
              widgetById={widgetById}
              widgetContainerWidth={widgetContainerWidth}
              widgetsList={widgetsList}
              deleteRow={deleteRow}
              changeRowLayout={changeRowLayout}
              editCell={editCell}
              deleteCell={deleteCell}
              insertRow={insertRow}
            />
          );
        })}
      </YStack>
    </View>
  );
};
