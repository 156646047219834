import React, {useMemo} from 'react';

import {
  AppPageType,
  AppContentPage,
  AppServicePage,
  getServicePageDefaults,
  AppStructure,
  Applet,
} from '@medad-sep/core';

import {Settings} from '../../../../../../screens/Settings';
import {MenuItem} from '../MenuItem';
import {PagesItems} from '../SideBarMenu';

interface MenuItemsParams {
  prePages: PagesItems;
  postPages: PagesItems;
  appStructure: AppStructure;
  applets: Applet[];
  currentPage?: string;
  isExpanded: boolean;
}

export const useMenuItems = ({
  prePages,
  postPages,
  appStructure,
  applets,
  currentPage,
  isExpanded,
}: MenuItemsParams) => {
  const createMenuItem = (pageData: any) => (
    <MenuItem key={pageData.id} isExpanded={isExpanded} pageData={pageData} isActive={currentPage === pageData.id} />
  );

  const getPageData = (page: any) => {
    if (page.type === AppPageType.CONTENT) {
      const {icon, title, id: route} = page as AppContentPage;
      return {icon, title, id: route};
    } else if (page.type === AppPageType.SERVICE) {
      const service = getServicePageDefaults(page as AppServicePage, applets);
      const {Icon: icon, name: title, id: route, hideNavigationHeader = false} = service || {};
      return {icon, title, id: route, hideNavigationHeader};
    }
  };

  return useMemo(() => {
    const homePageItems: React.JSX.Element[] = [];
    const otherPageItems: React.JSX.Element[] = [];

    const processPages = (pages: PagesItems) => {
      return pages.forEach((page) => {
        const menuItem = createMenuItem({id: page.id, icon: page.icon, title: page.title});
        page.id === appStructure.defaultHomePage ? homePageItems.push(menuItem) : otherPageItems.push(menuItem);
      });
    };

    processPages([...prePages, ...postPages.filter(({id}) => id !== Settings.id)]);

    appStructure?.pages.forEach((page) => {
      const pageData = getPageData(page);
      if (pageData && !pageData.hideNavigationHeader) {
        const menuItem = createMenuItem(pageData);
        pageData.id === appStructure.defaultHomePage ? homePageItems.push(menuItem) : otherPageItems.push(menuItem);
      }
    });

    return [...homePageItems, ...otherPageItems];
  }, [prePages, postPages, appStructure, applets, currentPage, isExpanded]);
};
