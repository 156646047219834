import React from 'react';

import {Button, IconView, shellScopedKey, Theme, useAppTheme} from '@medad-sep/core';

import {SettingsItem} from '../models';
const DarkModeSelector = (): JSX.Element => {
  const {theme, setTheme} = useAppTheme();
  return (
    <Button
      variant="empty"
      icon={<IconView color="$neutral-600" icon={theme === Theme.light ? 'Moon' : 'Sun'} size={17} />}
      onPress={() => setTheme(theme === Theme.light ? Theme.dark : Theme.light)}
    />
  );
};
export const DarkMode: SettingsItem = {
  id: 'dark-mode',
  title: shellScopedKey('settings.darkMode'),
  icon: 'Moon',
  action: <DarkModeSelector />,
};
