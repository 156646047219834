import {useMutation} from 'react-query';

import {useAPI} from '@medad-sep/core';

export function updateCalendarSettings(onSuccess) {
  const API = useAPI();
  return useMutation((body: any) => API.patch('user-settings/mySettings', {json: body}), {
    onSuccess,
  });
}
