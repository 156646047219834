import {useState, useEffect} from 'react';

import {v4 as uuid} from 'uuid';

import {getRequestErrorMessage, showToast, coreScopedKey, useResponsiveValue} from '@medad-sep/core';

import {useMyDashboardProfile} from '../api/useMyDashboardProfile';
import {useSaveDashboardProfile} from '../api/useSaveDashboardProfile';
import {useUserAvailableWidgets} from '../api/useUserAvailableWidgets';
import {DashboardContent, DashboardRow} from '../model';

export const useDashboardState = () => {
  const [dashboardContent, setDashboardContent] = useState<DashboardContent>([]);
  const [isEditMode, setIsEditMode] = useState(false);

  const {data: userDashboard} = useMyDashboardProfile();
  const {widgetById, widgetsList} = useUserAvailableWidgets();
  const {isLoading: isSavingDashboardProfile, mutateAsync: saveDashboardProfile} = useSaveDashboardProfile();

  // todo: revise
  const widgetContainerWidth = useResponsiveValue({$sm: '100%' as any, $md: 280, $lg: '20%'});

  useEffect(() => {
    setDashboardContent(userDashboard || []);
  }, [userDashboard]);

  const saveDashboardConfig = async () => {
    try {
      await saveDashboardProfile(dashboardContent.filter((row) => row.cells.some((cell) => cell.widgetId)));

      setIsEditMode(false);
      showToast({title: coreScopedKey('successMsg'), preset: 'done'});
    } catch (e) {
      const errorMessage = await getRequestErrorMessage(e);
      showToast({title: errorMessage, preset: 'error'});
    }
  };

  const insertRow = (layoutTemplate: number[], insertIndex: number) => {
    const newRow: DashboardRow = {
      id: uuid(),
      cells: layoutTemplate.map((flex) => ({id: uuid(), flex})),
    };

    setDashboardContent((prev) => {
      prev.splice(insertIndex, 0, newRow);
      return [...prev];
    });
  };

  const deleteRow = (rowIndex: number) => {
    setDashboardContent((prev) => {
      prev.splice(rowIndex, 1);
      return [...prev];
    });
  };

  const changeRowLayout = (rowIndex: number, newLayout: number[]) => {
    setDashboardContent((prev) => {
      prev[rowIndex].cells = newLayout.map((flex, cellIndex) => {
        return {id: uuid(), flex, widgetId: prev[rowIndex]?.cells?.[cellIndex]?.widgetId};
      });

      return [...prev];
    });
  };

  const editCell = (rowIndex: number, cellIndex: number, widgetId?: string) => {
    setDashboardContent((prev) => {
      prev[rowIndex].cells[cellIndex].widgetId = widgetId;
      return [...prev];
    });
  };

  const deleteCell = (rowIndex: number, columnIndex: number) => {
    setDashboardContent((prev) => {
      prev[rowIndex].cells.splice(columnIndex, 1);
      return [...prev];
    });
  };

  const isLoading = !dashboardContent;

  return {
    dashboardContent,
    isEditMode,
    widgetById,
    widgetsList,
    isSavingDashboardProfile,
    widgetContainerWidth,
    saveDashboardConfig,
    insertRow,
    deleteRow,
    changeRowLayout,
    editCell,
    deleteCell,
    setIsEditMode,
    isLoading,
  };
};
