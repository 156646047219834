import React from 'react';

import {Platform} from 'react-native';

import {XStack, IconView, View, useIsAuthenticated, useAuth, Button, CoreTrans} from '@medad-sep/core';

import {DarkMode} from '../../screens/Settings/sections/DarkMode';
import {Language} from '../../screens/Settings/sections/Language';
import {Logo} from '../Logo';

import {ProfileOptions} from './components/ProfileOptions';

export const MainHeader = (): JSX.Element => {
  const isMobile = Platform.OS !== 'web';

  const isAuthenticated = useIsAuthenticated();
  const {login} = useAuth();

  return (
    <XStack
      paddingBottom="$4"
      paddingHorizontal="$24"
      paddingVertical="$0"
      paddingTop={isMobile ? '$5' : '$0'}
      backgroundColor="$neutral-1050"
      justifyContent="space-between"
      alignItems="center"
      borderBottomColor="$border"
      borderBottomWidth="$0.5"
      height={isMobile ? '$10' : '$6'}>
      <View width="$18" $sm={{width: '$12'}}>
        <Logo />
      </View>

      <XStack alignItems="center" gap={isMobile ? '$8' : '$16'}>
        <XStack gap={isMobile ? '$6' : '$16'}>
          {DarkMode.action}
          {Language.action}
        </XStack>
        {isAuthenticated ? (
          <XStack alignItems="center" gap={isMobile ? '$12' : '$16'}>
            <Button variant="empty" icon={<IconView icon="Bell" size={17} color="$neutral-600" />} />

            <ProfileOptions />
          </XStack>
        ) : (
          <Button
            onPress={() => login()}
            fontWeight="500"
            variant="empty"
            color="$accent"
            prefix={<IconView icon="LogIn" accent size={17} />}>
            <CoreTrans i18nKey="login" />
          </Button>
        )}
      </XStack>
    </XStack>
  );
};
