import React from 'react';

import {shellScopedKey, YStack} from '@medad-sep/core';

import {SideBarMenu} from './SideBarMenu';

export const MainMenu = () => {
  return (
    <YStack backgroundColor="white" borderColor="transparent">
      <SideBarMenu
        highlightCurrentPage
        prePages={[
          {
            id: 'main',
            icon: 'Home',
            title: shellScopedKey('home'),
          },
        ]}
      />
    </YStack>
  );
};
