import React from 'react';

import {Platform} from 'react-native';

import {XStack, YStack} from '@medad-sep/core';

import {BreadrumbNavigator} from '../../BreadcrumbNavigator';
import {MainHeader} from '../../MainHeader';
import {MainStackNavigator, MainStackScreen} from '../../MainStackNavigator';

import {MainMenu} from './components/MainMenu';
import {Main} from './screens/Main';

export const DesktopLauncher = ({children}: any) => {
  const height = Platform.select({web: '100vh', default: '100%'});

  return (
    <YStack height={height}>
      <MainHeader />

      <XStack flex={1}>
        <MainMenu />

        <YStack flexGrow={1} overflow="scroll">
          <BreadrumbNavigator />
          <MainStackNavigator>
            <MainStackScreen
              initialParams={{title: 'main'}}
              name="main"
              component={Main}
              options={{headerShown: false}}
            />
            {children}
          </MainStackNavigator>
        </YStack>
      </XStack>
    </YStack>
  );
};
