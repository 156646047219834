import React, {useState} from 'react';

import {Trans} from 'react-i18next';
import {Platform} from 'react-native';

import {
  shellScopedKey,
  XStack,
  Typography,
  useAppletsList,
  Switch,
  ShellTrans,
  YStack,
  useUserSettings,
  Accordion,
  showToast,
  coreScopedKey,
} from '@medad-sep/core';

import {SettingsItem} from '../../models';

import {updateCalendarSettings} from './api/updateCalendarSettings';
import {SyncCalendar} from './SyncCalendar';

const Page = () => {
  const [serviceSettingLoading, setIsServiceSettingLoading] = useState<string>('');

  const {userSettings, refetch} = useUserSettings();
  const applets = useAppletsList();

  const calendarEventsSuccess = async () => {
    await refetch();
    showToast({title: coreScopedKey('successMsg'), preset: 'done'});
  };

  const {mutate: updateSettings} = updateCalendarSettings(calendarEventsSuccess);

  const updatedCalendarSettings = (checked: boolean, appletId: string, settings?: string[] | undefined) =>
    checked ? [appletId, ...(settings || [])] : [...(settings || [])].filter((item) => item !== appletId);

  const displayUserEvents = async (checked: boolean, appletId: string) => {
    setIsServiceSettingLoading(appletId);
    const showInCalendar = updatedCalendarSettings(checked, appletId, userSettings?.calendar?.showInCalendar);
    updateSettings({settings: {calendar: {...(userSettings?.calendar || {}), showInCalendar}}});

    setIsServiceSettingLoading('');
  };

  return (
    <YStack paddingHorizontal="$32" gap="$18" $sm={{paddingHorizontal: '$16'}}>
      <Accordion
        sections={[
          {
            title: <ShellTrans i18nKey="settings.calendar.displayEvents" />,
            content: applets.map((applet) => {
              return (
                <XStack
                  key={applet.id}
                  justifyContent="space-between"
                  marginVertical="$2"
                  marginHorizontal="$2"
                  alignItems="center"
                  borderRadius="$4">
                  <Typography variant="body2" fontWeight="700" marginHorizontal={2}>
                    <Trans i18nKey={applet.name as string} />
                  </Typography>
                  <Switch
                    size="$2"
                    isLoading={serviceSettingLoading === applet.id}
                    checked={!!userSettings?.calendar?.showInCalendar?.includes(applet.id)}
                    onCheckedChange={(checked) => displayUserEvents(checked, applet.id)}
                  />
                </XStack>
              );
            }),
          },
        ]}
      />

      {Platform.OS !== 'web' && <SyncCalendar />}
    </YStack>
  );
};

export const ScheduleSettings: SettingsItem = {
  id: 'schedule',
  title: shellScopedKey('settings.scheduleSetting'),
  icon: 'CalendarDays',
  Page,
  isPrivate: true,
};
