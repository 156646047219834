import React, {useState, ReactNode} from 'react';

import {useTranslation} from 'react-i18next';

import {Pressable, Dialog, XStack, ScrollView, shellScopedKey, getLocalizedText, AppletWidget} from '@medad-sep/core';

import {WidgetsTab} from './components/WidgetsTab';

interface Props {
  widgetsList?: AppletWidget[];
  triggerIcon: ReactNode;
  onWidgetSelect: (widgetId: string) => void;
}

export const WidgetSelector = ({widgetsList, triggerIcon, onWidgetSelect}: Props) => {
  const [isOpened, setIsOpened] = useState(false);
  const {t, i18n} = useTranslation();

  const handleDialogTrigger = () => {
    setIsOpened(true);
  };

  const onDialogOpen = (open: boolean) => {
    !open && setIsOpened(false);
  };

  const onCreateWidget = (widgetId: string) => {
    onWidgetSelect(widgetId);
    setIsOpened(false);
  };

  return (
    <Dialog
      hideFooter
      sideBarMode={false}
      open={isOpened}
      width="60%"
      height="60%"
      contentProps={{padding: 0, flex: 1}}
      onChange={onDialogOpen}
      trigger={
        <Pressable
          onPress={handleDialogTrigger}
          backgroundColor="$neutral-600"
          borderRadius="$rounded-full"
          alignItems="center"
          height="$2"
          padding="$1.5"
          justifyContent="center">
          {triggerIcon}
        </Pressable>
      }
      title={getLocalizedText({value: shellScopedKey('dashboard.addWidget'), t, i18n})}
      content={
        <XStack flex={1} width="100%" $sm={{flexDirection: 'column', height: '100%'}}>
          <ScrollView flex={1} height="100%" width="100%">
            <XStack flex={1} width="100%" flexWrap="wrap" $sm={{flexDirection: 'column'}}>
              <WidgetsTab widgetsList={widgetsList || []} onCreateWidget={onCreateWidget} />
            </XStack>
          </ScrollView>
        </XStack>
      }
    />
  );
};
