import React from 'react';

import {AppletService} from '@medad-sep/core';

import {MainStackScreen} from '../MainStackNavigator';

export const getServicePage = (service: AppletService): JSX.Element => {
  const Component = service?.Component;

  if (!Component) {
    return <></>;
  }

  return (
    <MainStackScreen
      key={service.id}
      name={service.id}
      component={Component}
      initialParams={{title: service.name}}
      options={{
        headerShown: false,
      }}
    />
  );
};
